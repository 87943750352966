window.Board = {
  store: {
    size: { width: 50, length: 50 },
    content: {},
  },

  numRow: 0,

  addEventListeners() {
    if (window.board) {
      window.board.querySelectorAll("[id^='seat']").forEach((seat) => {
        seat.addEventListener("click", (e) => {
          e.stopPropagation();
          if (
            seat.getAttribute("name").trim() != window.Ticket.getTicketName() ||
            window.Ticket.reservedSeats.includes(seat.getAttribute("id"))
          )
            return;
          window.Lib.moveSeatClipboard(seat);
        });
      });
      window.board.querySelectorAll("[id^='table']").forEach((table) => {
        table.addEventListener("click", (e) => {
          e.stopPropagation();
          if (
            table.getAttribute("name").trim() !=
              window.Ticket.getTicketName() ||
            window.Ticket.reservedSeats.includes(table.getAttribute("id")) ||
            window.Ticket.reservedSeats.some((t) =>
              t.startsWith(`table-seat-${table.getAttribute("id").slice(6)}`)
            )
          )
            return;
          window.Lib.moveSeatClipboard(table);
        });
      });
      window.board.querySelectorAll("[id^='stand']").forEach((stand) => {
        stand.addEventListener("click", (e) => {
          e.stopPropagation();
          if (
            stand.getAttribute("name").trim() != window.Ticket.getTicketName()
          )
            return;
          window.Lib.moveSeatClipboard(stand);
        });
      });
    }
  },

  init(seatingMap) {
    this.store = seatingMap || this.store;
    // window.State.initializeZoom(1);
    this.draw();
    return this;
  },

  draw() {
    let width = Number(this.store.size.width);
    let length = Number(this.store.size.length);

    if (window.board)
      window.board.setAttribute("viewBox", `0 0 ${width} ${length}`);

    let parsed = this.zParse();
    if (window.board) window.board.innerHTML = parsed;

    this.addEventListeners();

    return this;
  },

  zParse() {
    let bWidth = Number(this.store.size.width);
    let bLength = Number(this.store.size.length);
    let strokeWidth = 0.001 * ((bWidth + bLength) / 2);

    const addSeating = ({
      x,
      y,
      width,
      rows,
      rowLength,
      chairWidth,
      chairSpacing,
      color,
      cursor = "default",
      name,
    }) => {
      let result = "";

      for (let i = 1; i <= rows; i++) {
        let textFS = Math.min(bLength / 100, chairWidth * 1.2);
        let textX = x - textFS;
        let textY = y + ((2 * i - 1) * rowLength) / 2;
        let text = window.Lib.getLetterFromNum(++this.numRow);

        for (let j = 1; j <= width / (chairWidth + chairSpacing); j++) {
          let surplus =
            width -
            (chairWidth + chairSpacing) *
              Math.floor(width / (chairWidth + chairSpacing));

          let cy = y + (i * rowLength + (i - 1) * rowLength) / 2;
          let cx =
            x +
            j * (chairWidth + chairSpacing) -
            (chairWidth + chairSpacing) / 2 +
            surplus / 2;

          let r = chairWidth / 2;
          let textFS = 1.5 * r;

          result = result.concat(`
            <g name="${name}" id="seat-${text}-${j}" style="cursor: ${
            window.Ticket.reservedSeats.includes(`seat-${text}-${j}`)
              ? "default"
              : cursor
          }">
              <circle 
                cx="${cx}" 
                cy="${cy}" 
                r="${r}" 
                stroke="${color}" 
                fill="${
                  window.Ticket.reservedSeats.includes(`seat-${text}-${j}`)
                    ? "#ea0f30"
                    : "transparent"
                }"
                stroke-width="${strokeWidth}" 
                style="cursor: ${cursor}"
              />
              <text x="${cx}" y="${cy}" font-size="${textFS}" fill="${color}" text-anchor="middle" dominant-baseline="middle" style="font-family: monospace">
                ${j}
              </text>
            </g>  
          `);
        }

        result = result.concat(`
          <text x="${textX}" y="${textY}" font-size="${textFS}" fill="${color}" text-anchor="middle" dominant-baseline="middle" style="font-family: monospace">
            ${text}
          </text>
        `);
      }

      return result;
    };

    const addTable = ({
      x: rX,
      y: rY,
      width,
      rows,
      tableRowLength: rowLength,
      tableLength,
      tableWidth,
      tableChairWidth: chairWidth,
      tableSpacing,
      tableChairSpacing: chairSpacing,
      tableChairPositions: chairPositions,
      tableVerticalRadius,
      tableHorizontalRadius,
      color,
      cursor = "default",
      name,
    }) => {
      let result = "";

      for (let i = 1; i <= rows; i++) {
        let textFS = Math.min(bLength / 75, chairWidth * 1.5);
        let textX = rX - textFS;
        let textY = rY + ((2 * i - 1) * rowLength) / 2;
        let text = window.Lib.getLetterFromNum(++this.numRow);

        result = result.concat(`
          <text x="${textX}" y="${textY}" font-size="${textFS}" fill="${color}" text-anchor="middle" dominant-baseline="middle" style="font-family: monospace">
            ${text}
          </text>`);

        for (
          let j = 1;
          j <=
          (width + tableSpacing) / (tableWidth + 2 * chairWidth + tableSpacing);
          j++
        ) {
          let surplus =
            width +
            tableSpacing -
            (tableWidth + 2 * chairWidth + tableSpacing) *
              Math.floor(
                (width + tableSpacing) /
                  (tableWidth + 2 * chairWidth + tableSpacing)
              );

          let x =
            rX +
            (j - 1) * (tableWidth + 2 * chairWidth + tableSpacing) +
            chairWidth +
            surplus / 2;

          let y =
            rY +
            (i - 1) * rowLength +
            chairWidth +
            (rowLength - (tableLength + 2 * chairWidth)) / 2;

          let textX = x + tableWidth / 2;
          let textY = y + tableLength / 2;
          let textFS = Math.min(bLength / 75, tableLength / 2);

          let isReserved = window.Ticket.reservedSeats.some((t) =>
            t.startsWith(`table-seat-${text}-T${j}`)
          );

          result = result.concat(`
            <g name="${name}" id="table-${text}-T${j}" style="cursor: ${
            isReserved ? "default" : cursor
          }">
              <rect 
                x="${x}"
                y="${y}"
                rx="${tableVerticalRadius}"
                ry="${tableHorizontalRadius}"
                width="${tableWidth}"
                height="${tableLength}"
                fill="transparent"
                stroke="${color}"
                stroke-width="${strokeWidth}"
              />          
              <text x="${textX}" y="${textY}" font-size="${textFS}" fill="${color}" text-anchor="middle" dominant-baseline="middle" style="font-family: monospace">
                T${j}
              </text>
            </g>
          `);

          let numChairs = 0;

          if (chairPositions.includes("north"))
            for (
              let k = 1;
              k <= (tableWidth - chairSpacing) / (chairWidth + chairSpacing);
              k++
            ) {
              let surplus =
                tableWidth -
                chairSpacing -
                (chairWidth + chairSpacing) *
                  Math.floor(
                    (tableWidth - chairSpacing) / (chairWidth + chairSpacing)
                  );

              let r = chairWidth / 2;

              let cx =
                x +
                ((2 * k - 1) * chairWidth) / 2 +
                k * chairSpacing +
                surplus / 2;

              let cy = y - r;

              numChairs++;
              let isReserved = window.Ticket.reservedSeats.includes(
                `table-seat-${text}-T${j}-${numChairs}`
              );

              result = result.concat(`
                <circle
                  cx="${cx}"
                  cy="${cy}"
                  r="${r}"
                  stroke="${color}"
                  stroke-width="${strokeWidth}"
                  name="${name}"
                  id="table-seat-${text}-T${j}-${numChairs}"
                  fill="${isReserved ? "#ea0f30" : "transparent"}"
                  style="cursor: ${isReserved ? "default" : cursor}"
                />
              `);
            }

          if (chairPositions.includes("east"))
            for (
              let k = 1;
              k <= (tableLength - chairSpacing) / (chairWidth + chairSpacing);
              k++
            ) {
              let surplus =
                tableLength -
                chairSpacing -
                (chairWidth + chairSpacing) *
                  Math.floor(
                    (tableLength - chairSpacing) / (chairWidth + chairSpacing)
                  );

              let r = chairWidth / 2;
              let cx = x + tableWidth + r;

              let cy =
                y +
                ((2 * k - 1) * chairWidth) / 2 +
                k * chairSpacing +
                surplus / 2;

              numChairs++;
              let isReserved = window.Ticket.reservedSeats.includes(
                `table-seat-${text}-T${j}-${numChairs}`
              );

              result = result.concat(`
                <circle 
                  cx="${cx}"
                  cy="${cy}"
                  r="${r}"
                  stroke="${color}"
                  stroke-width="${strokeWidth}"
                  name="${name}"
                  id="table-seat-${text}-T${j}-${numChairs}"
                  fill="${isReserved ? "#ea0f30" : "transparent"}"
                  style="cursor: ${isReserved ? "default" : cursor}"
                />
              `);
            }

          if (chairPositions.includes("south"))
            for (
              let k = 1;
              k <= (tableWidth - chairSpacing) / (chairWidth + chairSpacing);
              k++
            ) {
              let tt = Math.floor(
                (tableWidth - chairSpacing) / (chairWidth + chairSpacing)
              );

              let surplus =
                tableWidth - chairSpacing - (chairWidth + chairSpacing) * tt;

              let r = chairWidth / 2;

              let cx =
                x +
                ((2 * k - 1) * chairWidth) / 2 +
                k * chairSpacing +
                surplus / 2;

              let cy = y + tableLength + r;

              numChairs++;
              let isReserved = window.Ticket.reservedSeats.includes(
                `table-seat-${text}-T${j}-${numChairs + tt - 2 * k + 1}`
              );

              result = result.concat(`
                <circle
                  cx="${cx}"
                  cy="${cy}"
                  r="${r}"
                  stroke="${color}"
                  stroke-width="${strokeWidth}"
                  name="${name}"
                  id="table-seat-${text}-T${j}-${numChairs + tt - 2 * k + 1}"
                  fill="${isReserved ? "#ea0f30" : "transparent"}"
                  style="cursor: ${isReserved ? "default" : cursor}"
                />
              `);
            }

          if (chairPositions.includes("west"))
            for (
              let k = 1;
              k <= (tableLength - chairSpacing) / (chairWidth + chairSpacing);
              k++
            ) {
              let tt = Math.floor(
                (tableLength - chairSpacing) / (chairWidth + chairSpacing)
              );
              let surplus =
                tableLength - chairSpacing - (chairWidth + chairSpacing) * tt;

              let r = chairWidth / 2;
              let cx = x - r;

              let cy =
                y +
                ((2 * k - 1) * chairWidth) / 2 +
                k * chairSpacing +
                surplus / 2;

              numChairs++;
              let isReserved = window.Ticket.reservedSeats.includes(
                `table-seat-${text}-T${j}-${numChairs + tt - 2 * k + 1}`
              );

              result = result.concat(`
                <circle 
                  cx="${cx}"
                  cy="${cy}"
                  r="${r}"
                  stroke="${color}"
                  stroke-width="${strokeWidth}"
                  name="${name}"
                  id="table-seat-${text}-T${j}-${numChairs + tt - 2 * k + 1}"
                  fill="${isReserved ? "#ea0f30" : "transparent"}"
                  style="cursor: ${isReserved ? "default" : cursor}"
                />
              `);
            }
        }
      }

      return result;
    };

    const addContent = (obj) => {
      if (!obj.content) return "";

      let result = "";
      let objContentIds = Object.keys(obj.content).sort(window.Lib.rowIdSortFn);

      for (let id of objContentIds) {
        let { x, y, width, length, name, type } = obj.content[id];

        let textFS = Math.min(
          Math.max(length / 2, bLength / 150),
          bLength / 50
        );
        let textX = x + width / 2;
        let textY = y + length / 2;
        let color = "black";
        let objType = id.startsWith("Area") ? "Area" : "Row";
        let elemId =
          objType === "Area"
            ? `area-${id.replace(/\-\-\w+/g, "")}`
            : objType === "Row"
            ? `row-${id.replace(/\-\-\w+/g, "")}`
            : "";

        if (objType === "Area") {
          result = result.concat(
            `<g id="${elemId}" fill="transparent" tab-index="0">
              <text x="${textX}" y="${textY}" font-size="${textFS}" fill="${color}" text-anchor="middle" dominant-baseline="middle" style="font-family: monospace">
                ${name}
              </text>
              <rect 
                x="${x}"
                y="${y}"
                width="${width}"
                height="${length}"
                fill="transparent"
                stroke="${color}"
                stroke-width="${strokeWidth}"
              />
            </g>`
          );
        } else if (objType === "Row") {
          let name = obj.content[id].name.trim();
          let color = name == window.Ticket.getTicketName() ? "#000" : "#888";
          let cursor =
            name == window.Ticket.getTicketName() ? "pointer" : "default";

          if (type == "seat") {
            let { rows, rowLength, chairWidth, chairSpacing, rowColor } =
              obj.content[id];
            let textFS = bLength / 90;
            result = result.concat(`
            <g id="${elemId}" tab-index="0">
            <text x=${x} y=${y - textFS} font-size="${textFS}" fill="${color}" dominant-baseline="middle" style="font-family: monospace">${name}</text>
              <rect 
                x="${x}"
                y="${y}"
                width="${width}"
                height="${rows * rowLength}"
                fill="${window.Lib.getRowColorRgb(rowColor)}"
                stroke="${color}"
                stroke-width="${strokeWidth}"
              />
              ${addSeating({
                x,
                y,
                width,
                rows,
                rowLength,
                chairWidth,
                chairSpacing,
                color,
                cursor,
                name,
              })} 
           </g>`);
          } else if (type == "table-seat") {
            let { rows, tableRowLength: rowLength, rowColor, chairWidth } = obj.content[id];
            let textFS = bLength / 90;
            result = result.concat(`
            <g id="${elemId}" tab-index="0">
              <text x=${x} y=${y - textFS} font-size="${textFS}" fill="${color}" dominant-baseline="middle" style="font-family: monospace">${name}</text>
              <rect 
                x="${x}"
                y="${y}"
                width="${width}"
                height="${rows * rowLength}"
                fill="${window.Lib.getRowColorRgb(rowColor)}"
                stroke="${color}"
                stroke-width="${strokeWidth}"
              />
              ${addTable({
                color,
                cursor,
                name,
                ...obj.content[id],
              })}
           </g>`);
          } else {
            let { rows, rowColor, rowCapacity } = obj.content[id];
            let tTextFS = bLength / 75;
            let tTextX = x - tTextFS;
            let tTextY = y + rows / 2;
            let tText = window.Lib.getLetterFromNum(++this.numRow);

            result = result.concat(`
              <text x="${tTextX}" y="${tTextY}" font-size="${tTextFS}" fill="${color}" text-anchor="middle" dominant-baseline="middle" style="font-family: monospace">
                ${tText}
              </text>`);

            let textFS = bLength / 90;
            let textX = x + width / 2;
            let textY1 = y + rows / 2 - textFS;
            let textY2 = y + rows / 2;
            let textY3 = y + rows / 2 + textFS;
            let elemId = id.match(/\d+$/)?.[0];

            result = result.concat(`
            <g id="stand-${tText}" name="${name}" style="cursor: ${cursor}" tab-index="0">
              <text x=${x} y=${y - textFS} font-size="${textFS}" fill="${color}" dominant-baseline="middle" style="font-family: monospace">${name}</text>
              <rect 
                x="${x}"
                y="${y}"
                width="${width}"
                height="${rows}"
                fill="${window.Lib.getRowColorRgb(rowColor)}"
                stroke="${color}"
                stroke-width="${strokeWidth}"
              />
              <text x="${textX}" y="${textY1}" font-size="${textFS}" fill="${color}" text-anchor="middle" dominant-baseline="middle" style="font-family: monospace">
                Standing
              </text>
              <text x="${textX}" y="${textY2}" font-size="${textFS}" fill="${color}" text-anchor="middle" dominant-baseline="middle" style="font-family: monospace">
                Row Capacity: ${rowCapacity}
              </text>
              <text x="${textX}" y="${textY3}" font-size="${textFS}" fill="${color}" text-anchor="middle" dominant-baseline="middle" style="font-family: monospace">
                Remaining: ${
                  rowCapacity - (window.Ticket.reservedStands[`stand-${tText}`] || 0)
                }
              </text>
            </g>
            `);
          }
        }
      }

      return result;
    };

    let result = `
      <g>
        <rect 
          x="0"
          y="0"
          width="${bWidth}"
          height="${bLength}"
          id="master"
          fill="white"
          stroke-width="${0 * ((bWidth + bLength) / 2)}"
        />
        ${addContent(this.store)}
      </g>
    `;

    this.numRow = 0;
    return result;
  },
};
